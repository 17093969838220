.Footer{  
    background-color:#363636; 
    padding: 35px 10px 20px 10px; 
    color: white;
    /* position: absolute;
    bottom: 0; */
    width: 100%;    
}
.Footer .contact-me p{  
     margin-bottom: 0;
     font-size: 14px;
}
.Footer .contact-me p span{  
    display: inline-block;
    width: 50px;
}


.Footer .social-links{  
    font-size: 24px;
}
.Footer .social-links span{  
    margin-right: 8px;
}
.Footer .social-links span a{  
    text-decoration: none;
    color: white;
    transition: all 0.3s ease;
}
.Footer .social-links span a:hover{      
    color: rgb(255, 198, 123);
}


.Footer .github-linkedin {     
    font-size: 22px;
}
.Footer .github-linkedin a{      
   text-decoration: none;
   color: white;
   transition: all 0.3s ease;
}
.Footer .github-linkedin a:hover{      
    color: rgb(255, 198, 123);
}
.Footer .github-linkedin i{      
    width: 30px;
}
.Footer .github-linkedin h4{      
    font-size: 22px;
}


.Footer .copyright{
    margin-top: 35px;   
}
.Footer .copyright p{    
    margin-bottom: 0px;
    font-size: 14px;
    text-align: center;
}
.Footer .copyright p span{    
    color: rgb(255, 198, 123);
}