.navbar {
    background: rgb(74, 74, 74);
    background: linear-gradient(90deg, rgba(74, 74, 74, 1) 0%, rgba(47, 47, 47, 1) 50%, rgba(23, 23, 23, 1) 100%);
}

.navbar-brand {
    color: rgb(255, 181, 128);
    font-size: 32px;
    font-family: 'Patrick Hand', cursive;
    text-transform: uppercase;
    font-weight: 600;
    transition: all 0.3s ease;
}

.navbar-brand span {
    color: rgb(240, 240, 240);
    font-family: 'Patrick Hand', cursive;
}

.navbar-brand:hover {
    color: rgb(240, 240, 240);
}

.navbar-brand:hover span {
    color: rgb(252, 174, 118);
}

.navbar-collapse {
    display: flex;
    justify-content: end;
}

.navbar-nav a {
    color: rgb(248, 248, 248);
    font-size: 16px;
}

.navbar-nav a:hover {
    color: rgb(255, 181, 128);
}

.navbar-nav a:focus {
    color: rgb(255, 181, 128);
}

.navbar-nav a i {
    margin-right: 8px;
}

.navbar-toggler-icon {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}