.header {
    background-color: #363636;
    background: rgb(74, 74, 74);
    background: linear-gradient(90deg, rgba(74, 74, 74, 1) 0%, rgba(47, 47, 47, 1) 50%, rgba(23, 23, 23, 1) 100%);
    padding-top: 50px;
    color: white;
}

.header .sub-title {
    color: rgb(255, 181, 128);
    letter-spacing: 3px;
}

.download-resume {
    background-color: rgb(255, 164, 98);
    font-weight: 600;
    border: none;
}

.download-resume:hover {
    background-color: rgb(255, 204, 168);
}

.download-resume:focus {
    box-shadow: none;
}

.header-image {
    width: 400px;
}

.header .linkedin {
    display: block;
    margin-top: 20px;
    color: white;
    transition: all 0.3s ease;
}

.header .linkedin h4 {
    padding-left: 5px;
    font-size: 18px;
}

.header .linkedin:hover {
    color: rgb(255, 181, 128);
    transform: translateX(5px);
}
.header-wrapper {
    position: relative;
    overflow: hidden;
}
.header-wrapper svg{
    position: absolute;
    bottom: 0;
    transform: scale(1.08);
    left: 0;
    width: 100%;
    height: 60px;
    fill: #fff;
    stroke: #fff;
    stroke-width: 3px;
}