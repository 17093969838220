.about-me-text{
    display: flex;
    justify-content: center;
    align-items: center;    
}

.about-me-text .about-me-text-container{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 50px 35px;
    border-radius: 5px;
}

.about-me-text p{
    font-size: 17px;
    text-align: justify;
}