.blog-timer div{
    background-color: #FF6A00;
    margin: 15px;
    color: whitesmoke;
    padding: 20px 30px;
    text-align: center;
}
.blog-timer div h1,h6{
    margin: 0;
}


@media screen and (max-width: 600px) {
    .blog-timer div{
        width: 100%;
        background-color: #FF6A00;
        margin: 3px;
        color: whitesmoke;
        padding: 10px 12px;
        text-align: center;
    }
}
