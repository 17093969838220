.projects-filter{
    text-align: center;
    margin-bottom: 25px;
}
.projects-filter button{
    border: none;   
    margin: 0 5px;
    border-radius: 8px;
    box-shadow: rgba(71, 51, 51, 0.3) 0px 2px 6px;
    background-color: #db762e;
    color: whitesmoke;
}
.projects-filter button:hover{    
    background-color: #eb8740;
    color: rgb(255, 255, 255);
}
.projects-display-cards{
    transition: all 0.3s ease;
}