.project-details{
    background-repeat: no-repeat;
    background-size:cover;
    background-attachment: fixed;    
}
.project-details-body{
    background-color: rgba(237, 237, 237, 0.85);
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.project-page-title{
    margin-top: 30px;
    margin-bottom: 30px;
    background-image: linear-gradient(to left, rgb(255, 168, 86), rgb(253, 206, 119));
    border-radius: 10px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}