.ProjectCard{
    transition: all 0.3s ease;
}
.ProjectCard a{
    text-decoration: none;
    color: rgb(58, 58, 58);
}
.ProjectCard p{
    margin-bottom: 0;
}

.ProjectCard .card{   
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; 
    border: none;
    transition: all 0.3s ease;
}
.ProjectCard .card:hover{   
    background-color: rgb(236, 236, 236);
    cursor: pointer;
}

.ProjectCard .technology{
    display: flex;
    flex-wrap: wrap;
}

.ProjectCard .technology p{
    padding: 4px 12px;
    border-radius: 15px;  
    margin: 3px;
    font-size: 13px;
}
.ProjectCard .technology .html{
    background-color: rgb(230, 63, 34);
    color: white;    
}
.ProjectCard .technology .css{
    background-color: #2062AF;
    color: white;    
}
.ProjectCard .technology .bootstrap{
    background-color: #4E1F92;
    color: white;    
}
.ProjectCard .technology .php{
    background-color: #5d6199;
    color: white;    
}
.ProjectCard .technology .mysql{
    background-color: #b97200;
    color: white;    
}
.ProjectCard .technology .javascript{
    background-color: #e4b600;
    color: white;    
}
.ProjectCard .technology .chartjs{
    background-color: #b84e52;
    color: white;    
}
.ProjectCard .technology .xml{
    background-color: #EE7801;
    color: white;    
}
.ProjectCard .technology .java{
    background-color: #E51F24;
    color: white;    
}
.ProjectCard .technology .sqlite{
    background-color: #033B50;
    color: white;    
}
.ProjectCard .technology .csharp{
    background-color: #240066;
    color: white;    
}
.ProjectCard .technology .dotnet{
    background-color: #0D6A99;
    color: white;    
}
.ProjectCard .technology .wordpress{
    background-color: #207196;
    color: white;    
}
.ProjectCard .technology .elementor{
    background-color: #D4245D;
    color: white;    
}
.ProjectCard .technology .woocommerce{
    background-color: #7B51AD;
    color: white;    
}
.ProjectCard .technology .react{
    background-color: #0c94ac;
    color: white;    
}
.ProjectCard .technology .express{
    background-color: #1d1d1d;
    color: white;    
}
.ProjectCard .technology .mongodb{
    background-color: #54B641;
    color: white;    
}
.ProjectCard .technology .firebase{
    background-color: #EE7E0C;
    color: white;    
}
.ProjectCard .technology .nextjs{ 
    background-color: #3f3f3f;
    color: white;    
}
.ProjectCard .technology .tailwindcss{ 
    background-color: #36b7f0;
    color: white;    
}



.ProjectCard .card-footer{
    background-color: transparent;
    border: none;
}
.ProjectCard .view-details-button{
    background-color: rgb(255, 164, 98);
    font-weight: 600;
}
.ProjectCard .view-details-button:hover{
    background-color: rgb(255, 204, 168);   
}