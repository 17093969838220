.course-timeline .course-timeline-item .course-card {
	max-height: 300px;
}

.course-timeline .course-timeline-item {
    display: flex;
    position: relative;
}

.course-timeline .course-timeline-item::before {
    background: #ffffff;
    content: "";
    height: calc(100% - 18px);
    left: 9px;
    position: absolute;
    top: 20px;
    width: 2px;
	z-index: 0;
}


.course-timeline .course-timeline-item .course-timeline-icon .course-badge i{
    font-size: 20px;
    color: rgb(255, 137, 94);   
    z-index: 1;    
}

.course-timeline .course-timeline-item .course-timeline-content {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0 0 0 1rem;
}

.view-certificate{
    text-decoration: none;
    font-weight: bold;
}

.course-date{
    color: rgb(255, 239, 206);
}
.course-heading{
    color: #e2e2e2;
}
.course-text{
    color: #fafafa;
}
.course-link{
    color: rgb(170, 204, 255);
    transition: all 0.3s ease;
}
.course-link:hover{
    color: rgb(221, 235, 255);
}