/* font-family: 'Koh Santepheap', cursive;
font-family: 'Patrick Hand', cursive; */
* {
    font-family: 'Roboto', sans-serif;
}

.section-title {
    padding-top: 60px;
    text-align: center;
    text-transform: uppercase;
}

.section-title::after {
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 30px;
    content: "";
    display: block;
    width: 150px;
    border-top: 4px solid rgb(255, 181, 128);
}

.project-subtitle {
    text-align: center;
    color: rgb(68, 68, 68);
    margin-top: 70px;
}

html,
body {
    /* height: 100%;
    position: relative; */
}

.App {
    /* min-height: 100vh; 
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: 250px;  */
}

.justified-p {
    text-align: justify;
}

.btn-check:focus+.btn, .btn:focus {
    box-shadow: none!important;
}